import Head from "next/head";
import React from "react";

import { addPropertiesIfFastlyUrl, getImageUrl } from "../util/img";

type Props = React.PropsWithChildren<{
    pageTitle: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
}>;

export const CustomHead: React.FC<Props> = React.memo(
    ({
        pageTitle,
        ogTitle = pageTitle,
        ogDescription = "We help you pack rooms. Software that makes selling out your shows easy.",
        ogImage = getImageUrl("/assets/cymbal-banner.webp"),
        children,
    }) => (
        <Head>
            <title>{pageTitle}</title>
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDescription} />
            <meta
                property="og:image"
                content={addPropertiesIfFastlyUrl(ogImage, {
                    width: 512,
                    height: 512,
                    fit: "cover",
                    format: "webp",
                })}
            />
            <meta property="og:type" content="website" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            {children}
        </Head>
    ),
);
