import React from "react";

export interface PagingState<T> {
    data: T[];
    pagesLoaded: number;
    isLoadingMore: boolean;
    isDoneLoadingMore: boolean;
    setIsLoadingMore: (isLoadingMore: boolean) => void;
    setData: React.Dispatch<React.SetStateAction<T[]>>;
    setPagesLoaded: React.Dispatch<React.SetStateAction<number>>;
    setIsDoneLoadingMore: (isDoneLoadingMore: boolean) => void;
}

export function usePagingParams<T>({
    firstPage,
    pagingLength,
}: {
    firstPage?: T[];
    pagingLength?: number;
} = {}): PagingState<T> {
    const [data, setData] = React.useState(firstPage ?? []);
    const [pagesLoaded, setPagesLoaded] = React.useState<number>(!firstPage ? 0 : 1);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const [isDoneLoadingMore, setIsDoneLoadingMore] = React.useState(
        pagesLoaded === 1 && pagingLength !== undefined && (firstPage ?? []).length < pagingLength,
    );

    React.useEffect(() => {
        if (!!firstPage) {
            const initialPagesLoaded = !firstPage ? 0 : 1;
            setData(firstPage);
            setPagesLoaded(initialPagesLoaded);
            setIsLoadingMore(false);
            setIsDoneLoadingMore(
                initialPagesLoaded === 1 && pagingLength !== undefined && firstPage.length < pagingLength,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstPage]);

    return {
        data,
        pagesLoaded,
        isLoadingMore,
        isDoneLoadingMore,
        setData,
        setPagesLoaded,
        setIsLoadingMore,
        setIsDoneLoadingMore,
    };
}
