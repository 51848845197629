import React from "react";
import { Event } from "../models/event";

export interface AllEventData {
    allEvents: Event[];
    isLoadingMoreAllEvents: boolean;
    isDoneLoadingMoreAllEvents: boolean;
    allEventsPagesLoaded: number;
    canLoadMoreAllEvents: boolean;
    setAllEvents: (allEvents: Event[]) => void;
    setIsLoadingMoreAllEvents: (isLoading: boolean) => void;
    setIsDoneLoadingMoreAllEvents: (isLoading: boolean) => void;
    setAllEventsPagesLoaded: (pagesLoaded: number) => void;
    loadNextPageAllEvents: () => void;
}

export const AllEventsContext = React.createContext({} as AllEventData);
