import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { v4 as uuid } from "uuid";

import { IconProps } from "../util/icon";

export const CheckCircleIcon: React.FC<IconProps> = (props) => {
    const [maskId, setMaskId] = React.useState("check-circle-mask");

    React.useEffect(() => setMaskId(uuid()), []);

    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <mask id={maskId}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM6.49035 13.6818C5.90456 13.096 5.90456 12.1462 6.49035 11.5604C7.07613 10.9746 8.02588 10.9746 8.61167 11.5604L10.3794 13.3282L16.3898 7.31779C16.9756 6.73201 17.9254 6.73201 18.5112 7.31779C19.0969 7.90358 19.097 8.85333 18.5112 9.43911L11.4401 16.5102C10.8543 17.096 9.90456 17.096 9.31878 16.5102L6.49035 13.6818Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM6.49035 13.6818C5.90456 13.096 5.90456 12.1462 6.49035 11.5604C7.07613 10.9746 8.02588 10.9746 8.61167 11.5604L10.3794 13.3282L16.3898 7.31779C16.9756 6.73201 17.9254 6.73201 18.5112 7.31779C19.0969 7.90358 19.097 8.85333 18.5112 9.43911L11.4401 16.5102C10.8543 17.096 9.90456 17.096 9.31878 16.5102L6.49035 13.6818Z"
            />
            <path
                d="M6.49035 11.5604L5.07613 10.1462L5.07613 10.1462L6.49035 11.5604ZM6.49035 13.6818L5.07613 15.096H5.07613L6.49035 13.6818ZM10.3794 13.3282L8.96522 14.7424L10.3794 16.1566L11.7936 14.7424L10.3794 13.3282ZM16.3898 7.31779L14.9756 5.90358V5.90358L16.3898 7.31779ZM18.5112 7.31779L17.0969 8.73201L17.0969 8.73201L18.5112 7.31779ZM18.5112 9.43911L19.9254 10.8533L18.5112 9.43911ZM11.4401 16.5102L10.0259 15.096L10.0259 15.096L11.4401 16.5102ZM9.31878 16.5102L10.733 15.096L10.733 15.096L9.31878 16.5102ZM22 12C22 17.5228 17.5228 22 12 22V26C19.732 26 26 19.732 26 12H22ZM12 2C17.5228 2 22 6.47715 22 12H26C26 4.26801 19.732 -2 12 -2V2ZM2 12C2 6.47715 6.47715 2 12 2V-2C4.26801 -2 -2 4.26801 -2 12H2ZM12 22C6.47715 22 2 17.5228 2 12H-2C-2 19.732 4.26801 26 12 26V22ZM5.07613 10.1462C3.7093 11.5131 3.7093 13.7291 5.07613 15.096L7.90456 12.2675C8.09982 12.4628 8.09982 12.7794 7.90456 12.9746L5.07613 10.1462ZM10.0259 10.1462C8.65905 8.77938 6.44297 8.77939 5.07613 10.1462L7.90456 12.9746C7.7093 13.1699 7.39272 13.1699 7.19745 12.9746L10.0259 10.1462ZM11.7936 11.914L10.0259 10.1462L7.19745 12.9746L8.96522 14.7424L11.7936 11.914ZM14.9756 5.90358L8.96522 11.914L11.7936 14.7424L17.8041 8.73201L14.9756 5.90358ZM19.9254 5.90358C18.5585 4.53674 16.3425 4.53675 14.9756 5.90358L17.8041 8.73201C17.6088 8.92727 17.2922 8.92727 17.0969 8.73201L19.9254 5.90358ZM19.9254 10.8533C21.2922 9.48649 21.2922 7.27041 19.9254 5.90358L17.0969 8.73201C16.9017 8.53675 16.9017 8.22017 17.097 8.0249L19.9254 10.8533ZM12.8543 17.9244L19.9254 10.8533L17.097 8.0249L10.0259 15.096L12.8543 17.9244ZM7.90456 17.9244C9.27139 19.2912 11.4875 19.2912 12.8543 17.9244L10.0259 15.096C10.2211 14.9007 10.5377 14.9007 10.733 15.096L7.90456 17.9244ZM5.07613 15.096L7.90456 17.9244L10.733 15.096L7.90456 12.2675L5.07613 15.096Z"
                mask={`url(#${maskId})`}
            />
        </SvgIcon>
    );
};
