export const OrganizationEventsPerPage = 24;

export const OrdersPerPage = 24;

export const SectionSalesPerPage = 24;

export const OrganizationMembersPerPage = 24;

export const OrganizationCustomersPerPage = 24;

export const SavedArtistsPerPage = 16;

export const SpotifyArtistSearchPerPage = 8;

export const CampaignsPerPage = 24;

export const CampaignRecipientsPerPage = 24;

export const PagesPerPage = 24;

export const SelectPagesPerPage = 24;

export const SearchOptionsPerPage = 16;

export const EmailCampaignFutureEventsPerPage = 20;

export const ContactsPerPage = 24;

export const TagsPerPage = 16;

export const LegoImagesPerPage = 20;

export const LegoFormSubmissionsPerPage = 20;

export const ShortLinksPerPage = 24;

export const AutomationAnalyticsContentPerPage = 24;

export const OrderUploadsPerPage = 20;
