import IconButton from "@mui/material/IconButton";
import Link from "next/link";
import React from "react";

import { LeftChevronIcon } from "../../icons/leftChevronIcon";
import { makeStyles } from "../../styles/makeStyles";

const useStyles = makeStyles()((theme) => ({
    leftChevron: {
        fontSize: 24,
        marginLeft: theme.spacing(-4),
    },
}));

interface Props {
    className?: string;
    link: string;
}

export const BackHeaderButton: React.FC<Props> = React.memo(({ className, link }) => {
    const { classes } = useStyles();

    return (
        <Link href={link} passHref legacyBehavior>
            <IconButton className={className}>
                <LeftChevronIcon className={classes.leftChevron} />
            </IconButton>
        </Link>
    );
});
