import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import { IconProps } from "../util/icon";

export const ProfileIcon: React.FC<IconProps> = (props) => (
    <SvgIcon viewBox="0 0 32 32" {...props}>
        <g clipPath="url(#clip0_175_6596)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 10C22 13.3137 19.3137 16 16 16C12.6863 16 10 13.3137 10 10C10 6.68629 12.6863 4 16 4C19.3137 4 22 6.68629 22 10ZM20.5 10C20.5 12.4853 18.4853 14.5 16 14.5C13.5147 14.5 11.5 12.4853 11.5 10C11.5 7.51472 13.5147 5.5 16 5.5C18.4853 5.5 20.5 7.51472 20.5 10Z"
            />
            <path d="M23.5 26C23.5 26.1591 23.495 26.317 23.4853 26.4737H24.9877C24.9959 26.3168 25 26.1589 25 26C25 21.0294 20.9706 17 16 17C11.0294 17 7 21.0294 7 26C7 26.1589 7.00412 26.3168 7.01225 26.4737H8.51472C8.50495 26.317 8.5 26.1591 8.5 26C8.5 21.8579 11.8579 18.5 16 18.5C20.1421 18.5 23.5 21.8579 23.5 26Z" />
        </g>
        <defs>
            <clipPath id="clip0_175_6596">
                <rect width="32" height="32" />
            </clipPath>
        </defs>
    </SvgIcon>
);
